.component-language-picker-btn {
  width: 130px;
  font-size: 14px;
  color: #9c9c9c;
  border-color: #c0c0c0;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .component-language-picker-btn-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      margin-right: 5px;
      width: 25px !important;
      height: auto !important;
    }
  }

  .component-language-picker-btn-icon {
    margin-left: 5px;
  }
}
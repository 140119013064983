.component-search-input-container {
  position: relative;

  .component-searach-input-selector {
    width: 250px;
    height: 200px;
    position: absolute;
    z-index: 1;
    bottom: -210px;
    left: -30px;
		box-shadow: 2px 2px 20px #ccc;
    overflow: auto;
    background-color: #fff;

    .adm-list-item-content-main {
      font-size: 14px;
    }
  }
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;

  .back {
    color: #1D65E3;
    font-size: 14px;
    margin-top: 10px;
  }

  button {
    width: 70%;
    margin-top: 30px;
  }

  img {
    width: 95px;
    height: 95px;
    margin-top: 140px;
    margin-bottom: 10px;
  }
}
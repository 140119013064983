.container {
  // background-color: #f8f8f8;

  :global {
    .component-header-container {
      border-color: transparent;
    }
  }

  .header {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    border-bottom: 2px solid #f0f0f0;
    background-color: #fff;

    img {
      height: 60%;
      width: auto;
    }
  }

  .no-register {
    width: 100%;
    height: calc(100% - 50px - 12px);
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    align-items: center;
    background-color: #fff;
    font-size: 16px;
    color: #606060;
    box-sizing: border-box;

    img {
      width: 202px;
      height: 161px;
    }

    button {
      width: 150px;
      height: 48px;
      margin-top: 40px;
      font-size: 16px;
    }
  }

  .register {
    padding-bottom: 140px;
    .card {
      border-bottom: 2px solid #f0f0f0;
      background-color: #fff;
      padding: 10px 10px;
    }

    .btn-group {
      @extend .card;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 10px;
      border-color: transparent;
      position: fixed;
      bottom: 0;

      .top-btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        button {
          flex: 1;
          width: 40%;
        }
      }

      &>span {
        color: #1D65E3;
        margin-top: 10px;
      }
      // button {
      //   width: 100%;

      //   &:first-child {
      //     margin-top: 0;
      //   }
      // }
    }

    .row {
      display: flex;
      align-items: center;
      font-size: 16px;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      &>span:first-child {
        color: #717171;
      }

      &>span:last-child {

      }
    }

    .row2 {
      display: flex;
      flex-direction: column;
      font-size: 16px;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      &>span:first-child {
        color: #717171;
      }
    }

    .register-notice {
      width: 100%;
      background-color: #fff;
      padding-bottom: 50px;
      padding-top: 70px;
      font-size: 16px;
      color: #575757;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 150px;
        height: 156px;
      }
    }
  }
  
  .link {
    color: blue;
  }
}
.component-upload-preview {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: auto;
  padding: 30px 20px;
  box-sizing: border-box;

  .component-upload-preview-img {
    width: 100%;
  }
}
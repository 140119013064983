.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;

  .card {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 50px;
    margin: 0 auto;
  }

  .row {
      display: flex;
      align-items: center;
      font-size: 16px;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      &>span:first-child {
        color: #717171;
      }

      &>span:last-child {

      }
    }

  .back {
    color: #1D65E3;
    font-size: 14px;
    margin-top: 10px;
  }

  button {
    width: 70%;
    margin-top: 30px;
  }

  img {
    width: 95px;
    height: 95px;
    margin-top: 140px;
    margin-bottom: 10px;
  }
}
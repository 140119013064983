.container {
  background-color: #f8f8f8;

  .card {
    padding: 10px 18px;
    border-bottom: 6px solid #f8f8f8;
    background-color: #fff;
    font-size: 16px;

    &:last-child {
      border-color: transparent;
    }

    .row {
      display: flex;
      align-items: center;
      margin-top: 10px;

      &>span:first-child {
        color: #717171;
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
html,
body,
p {
  margin: 0;
  padding: 0;
}

.adm-list-item {
  padding-left: 0;
}

.adm-list-item-content {
  padding-left: 10px;

  .adm-form-item-required-asterisk {
    left: 0 !important;
  }
}

.adm-list-item-content-main {
  height: 100%;
  /* display: flex;
  align-items: center; */

  .adm-form-item-child {
    width: 100%;
    height: 100%;

    .adm-form-item-child-inner {
      width: 100%;
      height: 100%;
    }
  }
}

.adm-form-item-label {
  font-size: 15px;
  padding-left: 16px;
  color: #000;
}

.adm-input-element {
  font-size: 15px;
}

.component-input-btn-placeholder {
  color: #999999;
}

.adm-list-body {
  border-top: 0;
  overflow: visible;
}


:root{
  /* 主色调 */
  --primary-color: #1da2db; 

  /* 次要的 */
  /* --secondary-color: #A6A6A6; */

  /* ant mobile 主色调 */
  --adm-color-primary: var(--primary-color);
}

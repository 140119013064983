.container {
  .phone-area {
    display: flex;
    align-items: center;
  }

  .tel-item {
    :global {
      .adm-list-item-content {
        border-top: 0;
      }
    }
  }
}

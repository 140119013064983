.container {
  background-color: #f8f8f8;

  .card {
    padding: 10px 18px;
    margin-top: 6px;
    background-color: #fff;
    font-size: 16px;

    &:first-child {
      margin-top: 0;
    }

    .row {
      display: flex;
      align-items: center;
      margin-top: 10px;

      &>span:first-child {
        color: #717171;
      }

      &:first-child {
        margin-top: 0;
      }
    }

    .row2 {
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      &>span:first-child {
        color: #717171;
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .btn {
    width: 90%;
    position: fixed;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 20px;
  }
}
.component-header-container {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
  padding: 12px 14px;

  .component-header-back-box {
    width: 70px;
    display: flex;
    justify-content: flex-start;
    
    &>img {
      width: 16px;
      height: 16px;
      transform: rotateY(180deg);
    }
  }

  .component-header-title {
    width: calc(100% - 140px);
    font-size: 15px;
    font-weight: 500;
    text-align: center;
  }

  .component-header-right-box {
    width: 70px;
    display: flex;
    justify-content: flex-end;
  }
}
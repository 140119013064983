.component-page-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom, 30px); /* 兼容 iOS >= 11.2 */

  .component-header-message {
    width: 20px;
    height: 20px;
  }

  .component-page-content {
    flex: 1;
    width: 100%;
    overflow: auto;
    position: relative;
    box-sizing: border-box;
  }
}
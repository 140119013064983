.component-select-btn {
  width: 100%;
  height: 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;

  &.component-select-btn-disabled {
    color: #acacac;
  }

  .component-select-placeholder {
    color: #cccccc;
  }
}